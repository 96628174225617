import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Box } from '@wix/design-system';
import { animateScroll } from 'react-scroll';
import { useRouter } from 'next/router';
import { Article, ArticleResolution, ArticleType } from '@wix/answers-api';
import { useExperiments } from '@wix/fe-essentials-standalone';
import classNames from 'classnames';
import { BI, EXPERIMENTS, LOCALES } from '../../constants';
import { useBI } from '../../hooks/useBI';
import { ArticleHeader } from './ArticleHeader';
import { pushUrl } from '../InnerLink';
import { ArticleFooter } from './ArticleFooter';
import useYouTubePlayers from '../../hooks/useYouTubePlayers';
import { EditorXNotification } from '../EditorXNotification';
import { normalizeArticle } from '../../services/answers/utils';
import { stripHTML } from '../../utils/text';
import { ArticleViewerWrapper } from './ArticleViewerWrapper';
import { useArticleContentBi } from './bi';
import { getTopOffsetForScrollToArticlePage } from '../../utils/scroll';
import { Step } from '../../pages/article/[articleTitle]';
import css from './index.module.scss';

type ArticleContentProps = {
  article: Article;
  isRoadmapArticle?: boolean;
  readingTimeInMinutes?: number;
  onClickStatus?: (resolution: ArticleResolution) => void;
  showEditorXNotification?: boolean;
  relatedArticles: Article[];
  steps: Step[];
};

export const ArticleContent: FunctionComponent<ArticleContentProps> = ({
  article,
  isRoadmapArticle,
  onClickStatus,
  readingTimeInMinutes,
  showEditorXNotification,
  relatedArticles,
  steps
}: ArticleContentProps) => {
  const {
    id: articleId,
    type,
    content,
    url: articleUrl,
    title,
    resolution,
    resolutionEta,
  } = article;
  const { locale, query } = useRouter();
  const containerRef = useRef<HTMLDivElement>(null);
  useYouTubePlayers(containerRef, articleId);
  const { sendBIEvent } = useBI();
  const { onLinkClickBi } = useArticleContentBi(articleId, type);
  const { experiments } = useExperiments({ readOnly: true });
  const isArticlePageUXChangesEnabled = experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PAGE_UX_CHANGES);

  const showDisclaimerForFeatureRequest =
    type === ArticleType.FEATURE_REQUEST || isRoadmapArticle;

  const [showEditorXFloatingNotification, setShowEditorXNotification] =
    React.useState(showEditorXNotification);
  const URL_TABS_SEPARATOR = '?tabs=';
  const URL_HEADING_SEPARATOR = ',';
  const isSSR = () => typeof window === 'undefined';
  const shouldOpenInNewTab = (e: MouseEvent): boolean =>
    (e.currentTarget as HTMLAnchorElement).target === '_blank' ||
    e.ctrlKey ||
    e.metaKey;

  const handleAnchorClick = (e: MouseEvent, hash: string) => {
    e.preventDefault();
    const boundingClientRect = document
      ?.getElementById(hash)
      ?.getBoundingClientRect();

    if (boundingClientRect) {
      animateScroll.scrollTo(
        boundingClientRect.top +
        window.scrollY -
        getTopOffsetForScrollToArticlePage()
      );
      location.hash = hash;
    }
  };

  const getAllOtherTabSlugs = (tabsList: string, currentTabSlugs: string[]) => {
    if (tabsList) {
      const unrelatedSlugs = tabsList
        .split(URL_HEADING_SEPARATOR)
        .map((slug) => decodeURI(slug))
        .filter((slug) => !currentTabSlugs.includes(slug));
      return unrelatedSlugs.length
        ? unrelatedSlugs.join(URL_HEADING_SEPARATOR) + URL_HEADING_SEPARATOR
        : '';
    }
    return '';
  };

  const onTabClick = async (selectedTab: number, currentTabSlugs: string[]) => {
    if (!isSSR()) {
      if (currentTabSlugs.length) {
        const hash = document.location.hash;
        const tabs = new URLSearchParams(document.location.search).get('tabs');
        const tabsSearchParams = tabs ? tabs.toString() : '';
        const allUnrelatedSlugsInURL = getAllOtherTabSlugs(
          tabsSearchParams,
          currentTabSlugs
        );
        const selectedTabURLSlug = currentTabSlugs[selectedTab];
        const newURLState = `${location.pathname}${URL_TABS_SEPARATOR}${allUnrelatedSlugsInURL}${selectedTabURLSlug}${hash}`;
        history.pushState(null, '', newURLState);
      }
    }
  };

  const maybeGetSelectedTabsFromURL = () => {
    if (!isSSR()) {
      const maybeURLTabs = query.tabs ? query.tabs.toString() : '';
      if (maybeURLTabs.length) {
        return maybeURLTabs
          .split(URL_HEADING_SEPARATOR)
          .map((slug) => decodeURI(slug));
      } else {
        return [];
      }
    }
    return [];
  };

  const internalAnchors = useCallback(() => {
    const allArticleLinks = document.querySelectorAll('a');
    return Array.from(allArticleLinks)
      .filter((link) => {
        const [linkWithoutHash, hash] = link.href.split('#');
        const sameArticle = decodeURI(linkWithoutHash) === articleUrl;
        return sameArticle && hash;
      })
      .reduce((acc, link, index) => {
        const href = link.href;
        acc[href] = (index + 1).toString();
        return acc;
      }, {} as Record<string, string>);
  }, [articleUrl]);

  const onLinkClick = useCallback(
    async (e: MouseEvent, href: string) => {
      const target = e.target as HTMLElement;
      const text = target?.textContent as string;
      const {
        CUSTOM_HTML_ARTICLE_CTA_BUTTON,
        CUSTOM_HTML_ARTICLE_LOG_IN_BUTTON,
        CUSTOM_HTML_ARTICLE_GET_STARTED_BUTTON,
      } = BI.SELECTORS;

      const isButton = [
        CUSTOM_HTML_ARTICLE_CTA_BUTTON,
        CUSTOM_HTML_ARTICLE_LOG_IN_BUTTON,
        CUSTOM_HTML_ARTICLE_GET_STARTED_BUTTON,
      ].some((cta) => target.getAttribute('class')?.includes(cta));
      const [linkWithoutHash, hash] = href.split('#');
      const isOtherArticle = decodeURI(linkWithoutHash) !== articleUrl;
      let clickedItemType, clientSideNavigation, clickedItemOrder;
      if (isButton) {
        clickedItemType = BI.CLICKED_ITEM_TYPES.CTA;
      } else if (isOtherArticle) {
        if (isLinkToArticle(href, locale as string)) {
          if (!shouldOpenInNewTab(e)) {
            e.preventDefault();
            clientSideNavigation = true;
          } else {
            clientSideNavigation = false;
          }
          clickedItemType = BI.CLICKED_ITEM_TYPES.ARTICLE_LINK;
        } else {
          clickedItemType = BI.CLICKED_ITEM_TYPES.EXTERNAL_LINK;
        }
      } else {
        clickedItemType = BI.CLICKED_ITEM_TYPES.INTERNAL_ANCHOR;
        clickedItemOrder = internalAnchors()[href];
        handleAnchorClick(e, hash);
      }

      await onLinkClickBi(href, clickedItemType, text, clickedItemOrder);

      if (clientSideNavigation) {
        const { pathname, search, hash: hashSource } = new URL(href);
        const url = `${pathname}${search}${hashSource}`;
        let linkLocale: string | undefined = url.substring(1, 3);
        linkLocale = Object.values(LOCALES).includes(linkLocale)
          ? linkLocale
          : undefined;
        await pushUrl(url, false, linkLocale);
      }
    },
    [articleUrl, articleId, locale, sendBIEvent, type, pushUrl]
  );

  const containsContent = useMemo(
    () => stripHTML(content).length > 0,
    [content]
  );

  useEffect(() => {
    const targetId = window.location.hash.substring(1);
    let boundingClientRect = document
      ?.getElementById(targetId)
      ?.getBoundingClientRect();

    if (boundingClientRect) {
      const images = document.querySelectorAll('img');
      let loadedImagesCount = 0;

      const gotoAnchorPosition = () => {
        boundingClientRect = document
          ?.getElementById(targetId)
          ?.getBoundingClientRect();
        if (boundingClientRect) {
          animateScroll.scrollTo(
            boundingClientRect.top +
            window.scrollY -
            getTopOffsetForScrollToArticlePage()
          );
        }
      };

      const checkIfAllImagesLoaded = () => {
        loadedImagesCount++;
        if (loadedImagesCount === images.length) {
          gotoAnchorPosition();
        }
      };

      images.forEach((img) => {
        if (img.complete) {
          checkIfAllImagesLoaded();
        } else {
          img.addEventListener('load', checkIfAllImagesLoaded);
          img.addEventListener('error', checkIfAllImagesLoaded);
        }
      });

      if (images.length === 0) {
        gotoAnchorPosition();
      }

      return () =>
        images.forEach((img) => {
          img.removeEventListener('load', checkIfAllImagesLoaded);
          img.removeEventListener('error', checkIfAllImagesLoaded);
        });
    }
  }, [articleId]);

  return content ? (
    <Box
      direction="vertical"
      className={classNames({
        [css.articleWrapperContainer]: !isArticlePageUXChangesEnabled,
        [css.articleWrapperContainerNew]: isArticlePageUXChangesEnabled,
        [css.articleWrapperContainerNewKnownIssue]: isArticlePageUXChangesEnabled && article.type === ArticleType.KNOWN_ISSUE && !containsContent
      })}
    >
      <Box direction="vertical" className={classNames(css.articleWrapper, {
        [css.articleWrapperNew]: isArticlePageUXChangesEnabled,
        [css.knownIssue]: isArticlePageUXChangesEnabled && article.type === ArticleType.KNOWN_ISSUE
      })} align="center">
        <Box direction="vertical" className={css.articleViewerWrapper}>
          <ArticleHeader
            type={type}
            title={title}
            resolution={resolution}
            resolutionEta={resolutionEta as unknown as Long}
            isRoadmapArticle={isRoadmapArticle}
            readingTime={readingTimeInMinutes}
            creationDate={article.creationDate}
            onClickStatus={onClickStatus}
            steps={steps}
          />
          {showEditorXFloatingNotification && (
            <Box
              className={classNames(css.floatingNotificationEditorX, {
                [css.readingTime]:
                  readingTimeInMinutes && type === ArticleType.ARTICLE,
              })}
            >
              <EditorXNotification
                isArticle={true}
                onClose={() => setShowEditorXNotification(false)}
              />
            </Box>
          )}
          {containsContent ? (
            <ArticleViewerWrapper
              article={article}
              showEditorXFloatingNotification={showEditorXFloatingNotification}
              onLinkClick={onLinkClick}
              onTabClick={onTabClick}
              maybeGetSelectedTabsFromURL={maybeGetSelectedTabsFromURL}
            />
          ) : null}
        </Box>
      </Box>
      <ArticleFooter
        article={normalizeArticle(article)}
        isRoadmapArticle={showDisclaimerForFeatureRequest}
        hasNoContent={!containsContent}
        relatedArticles={relatedArticles}
      />
    </Box>
  ) : null;
};

const isLinkToArticle = (href: string, locale: string) =>
  href.includes(`/${locale}/article/`) || href.includes('/en/article/');
