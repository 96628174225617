import { Badge, Box, Heading, Text } from '@wix/design-system';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { useTranslation } from '@wix/wix-i18n-config';
import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import { EXPERIMENTS } from '../../constants';
import { DATA_HOOKS } from '../../dataHooks';
import { VoteOptions } from './../../types';
import { AdditionalFeedbackField } from './AdditionalFeedbackField';
import css from './index.module.scss';

export type VisibleFeedbackProps = {
  articleId: string;
  categoryId: string;
};

export const VisibleFeedback: FunctionComponent<VisibleFeedbackProps> = ({
  articleId,
  categoryId
}: VisibleFeedbackProps) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<VoteOptions>();
  const { experiments } = useExperiments({ suspense: true, readOnly: true });

  const options = [VoteOptions.YES, VoteOptions.UNHELPFUL, VoteOptions.UNSATISFIED, VoteOptions.OTHER];
  const shouldShowAdditionalFeedbackText = selectedOption && selectedOption !== VoteOptions.YES;
  const isArticlePageUXChangesEnabled = experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PAGE_UX_CHANGES);

  return (
    <Box direction='vertical' className={classNames(css.wrapper, { [css.sideBar]: isArticlePageUXChangesEnabled })}>
      <Heading className={css.title} size='tiny'>
        {t('visible-feedback.title')}
      </Heading>

      <Box direction='vertical' className={css.feedbackOptionsContainer} gap='9px'>
        {options.map((option) => (
          <Badge key={option} className={classNames(css.feedbackOption, { [css.selected]: option === selectedOption })}
            onClick={() => setSelectedOption(option)} type={option === selectedOption ? 'solid' : 'outlined'}
            skin='neutralStandard' size='medium' uppercase={false} dataHook={`${DATA_HOOKS.VISIBLE_FEEDBACK_OPTION}-${option} ${option === selectedOption ? DATA_HOOKS.VISIBLE_FEEDBACK_OPTION_SELECTED : ''}`}>
            <Text className={css.feedbackOptionText} size='small' weight='normal'>
              {t(`visible-feedback.${option}`)}
            </Text>
          </Badge>
        ))}
      </Box>
      {shouldShowAdditionalFeedbackText ? <AdditionalFeedbackField onSendFeedback={() => { }} /> : null}
    </Box>
  );
};
