import { useRouter } from 'next/router';
import { Article } from '@wix/answers-api';
import { Box, Text } from '@wix/design-system';
import { FunctionComponent } from 'react';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { useTranslation } from '@wix/wix-i18n-config';
import Anchors, { AnchorsState } from '@wix/cc-user-facing-common-components/Anchors';
import classNames from 'classnames';
import { BI, EXPERIMENTS, PAGES } from '../../constants';
import { Step } from '../../pages/article/[articleTitle]';
import { mapArticleTypeToItemType } from '../../utils/bi';
import { RelatedArticles } from '../RelatedArticles';
import { HirePartner } from './HirePartner';
import { StudioEntryBanner } from './StudioEntryBanner';
import { ArticleFeedback } from '../ArticleFeedback';
import { VisibleFeedback } from '../ArticleVisibleFeedback';
import { useBI } from '../../hooks/useBI';
import css from './index.module.scss';

type ArticleSideBarProps = {
  article: Article;
  steps: Step[];
  isWixStudioUser: boolean;
  relatedArticles: Article[];
};

export const ArticleSideBar: FunctionComponent<ArticleSideBarProps> = ({
  article,
  steps,
  isWixStudioUser,
  relatedArticles,
}: ArticleSideBarProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();
  const { experiments } = useExperiments({ readOnly: true });
  const isArticlePageUXChangesEnabled = experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PAGE_UX_CHANGES);
  const isNewFeedbackEnabled = experiments.enabled(EXPERIMENTS.SPECS.VISIBLE_ARTICLE_FEEDBACK);

  const onAnchorClick = async (text: string, order: string) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ARTICLE,
        kb_lang: locale as string,
        item_id: article.id,
        item_type: mapArticleTypeToItemType(article.type),
        clicked_item_type: BI.CLICKED_ITEM_TYPES.SIDEBAR_ANCHOR,
        clicked_text: text,
        clicked_item_order: order,
        is_page_content_click: true,
      })
    );
  };

  return (
    <Box direction="vertical" className={classNames(css.wrapper, {
      [css.stickyWrapper]: isArticlePageUXChangesEnabled,
    })}>
      {steps.length > 0 && <Box className={css.anchorsWrapper} direction="vertical">
        <Text weight="bold">{t('scrollspy.title')}</Text>
        <Anchors state={AnchorsState.SCROLLSPY} steps={steps} onClick={onAnchorClick} hideTitle={true} locale={locale as string} />
      </Box>}
      {isArticlePageUXChangesEnabled ?
        <>
          {isNewFeedbackEnabled ? <VisibleFeedback articleId={article.id} categoryId={article.categoryId} /> :
            <ArticleFeedback articleId={article.id} categoryId={article.categoryId} />}
        </>
        :
        <>
          <RelatedArticles
            itemId={article.id}
            itemType={mapArticleTypeToItemType(article.type)}
            relatedArticles={relatedArticles}
            pageType={PAGES.ARTICLE}
          />
          {isWixStudioUser ? (
            <StudioEntryBanner articleId={article.id} articleType={article.type} />
          ) : (
            <HirePartner articleId={article.id} categoryId={article.categoryId} />
          )}
        </>
      }
    </Box>
  );
};
